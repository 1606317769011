import { useState } from "react"
import { Helmet } from "react-helmet"
import { Map } from "../Map"
import { OverMap } from "../OverMap"
import { Clients } from "../Clients"

const defaultMap = {
      zoom: 12,
      lat: 51.39590,
      lng: -2.37186,
      marker: true,
      marker_position: { lat: 0, lng: 0 }
}

export function Home() {

      let [ zoom, setZoom ] = useState(defaultMap.zoom)
      let [ center, setCentre ] = useState({ lat: defaultMap.lat, lng: defaultMap.lng })
      let [ marker, setMarker ] = useState(defaultMap.marker)
      let [ markerPosition, setMarkerPosition ] = useState(defaultMap.marker_position)

      const setMap = (zoom = defaultMap.zoom, lat = defaultMap.lat, lng = defaultMap.lng, marker = false, offset = false) => {

            setZoom(zoom)

            if (marker) {
                  setMarker(true)
                  setMarkerPosition({ lat, lng })
            } else {
                  setMarker(false)
            }

            if (offset) {
                  const offset = 0.0321425
                  const newLng = parseFloat(lng) + offset
                  setCentre({ lat, lng: newLng })
            }
      }

      return (
            <>

                  <Helmet>
                        <meta charSet="utf-8" />
                        <title>Skip Hire Chippenham, Swindon & Bristol | Book Online | MJ Church</title>
                        <link rel="canonical" href="https://www.mjchurchskiphire.co.uk" />
                        <meta name="description" content="MJ Church are the No 1 Local Skip Hire company. For over 30 years we've been servicing our loyal local customers with an excellent 'One-Stop Shop' skip hire service." />
                  </Helmet>

                  <div id="map-wrapper">

                        <div id="google-map">
                              <Map zoom={zoom} center={center} marker={marker} markerPosition={markerPosition} />
                        </div>

                        <OverMap updateFunc={setMap} />

                  </div>

                  <Clients />

            </>
      )
}