import { AwsRum, AwsRumConfig } from 'aws-rum-web'

import { config } from "./libs/config"
import { sessionHandler } from "./libs/helper"

import { useEffect, useReducer, createContext } from "react"
import { Routes, Route, Navigate } from "react-router-dom"

import { Home } from './components/pages/Home'
import { Order } from './components/pages/Order'

import { PaymentNotify } from './components/pages/PaymentNotify'
import { PaymentProblem } from './components/pages/PaymentProblem'
import { Complete } from './components/pages/Complete'

import { Services } from './components/pages/static/Services'
import { About } from './components/pages/static/About'
import { Privacy } from './components/pages/static/Privacy'
import { Terms } from './components/pages/static/Terms'
import { Contact } from './components/pages/static/Contact'

import { Header } from './components/includes/Header'
import { Footer } from './components/includes/Footer'

import ReactGA from "react-ga4"

// AWS RUM
try {
      const config = {
            sessionSampleRate: 1,
            guestRoleArn: "arn:aws:iam::053063766038:role/RUM-Monitor-eu-west-2-053063766038-1411095121861-Unauth",
            identityPoolId: "eu-west-2:ac851d6a-1d19-4f33-8d50-fd6d788858da",
            endpoint: "https://dataplane.rum.eu-west-2.amazonaws.com",
            telemetries: [ "performance", "errors", "http" ],
            allowCookies: true,
            enableXRay: false
      }

      const APPLICATION_ID = 'c957b170-782a-42ef-a278-df9451fb6145'
      const APPLICATION_VERSION = '1.0.0'
      const APPLICATION_REGION = 'eu-west-2'

      const awsRum = new AwsRum(
            APPLICATION_ID,
            APPLICATION_VERSION,
            APPLICATION_REGION,
            config
      )
} catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
}

// Establish environment
const env = window.location.host.includes("www") ? "prod" : "stage"
const c = config[ env ]

// Initialise React-GA4
ReactGA.initialize(c.ga4_measurement_id)
ReactGA.send({ hitType: "pageview", page: window.location.pathname })

// Create context layer
export const MyContext = createContext()

// Main App function
export default function App() {

      const [ AppData, setAppData ] = useReducer((oldData, newItem) => { return ({ ...oldData, ...newItem }) }, c)
      const [ UserData ] = useReducer((oldData, newItem) => { return ({ ...oldData, ...newItem }) }, null)

      // console.log('AppData', JSON.stringify(AppData))

      useEffect(() => {
            sessionHandler()
      }, [])

      return (
            <>
                  <MyContext.Provider value={{ AppData, setAppData, UserData }}>
                        <Header />
                        <Routes>
                              <Route path="/" element={<Home />} />
                              <Route path="/order" element={<Order />} />
                              <Route path="/payment/notify" element={<PaymentNotify />} />
                              <Route path="/payment/problem" element={<PaymentProblem />} />
                              <Route path="/complete" element={<Complete />} />
                              <Route path="/services" element={<Services />} />
                              <Route path="/about" element={<About />} />
                              <Route path="/privacy" element={<Privacy />} />
                              <Route path="/terms" element={<Terms />} />
                              <Route path="/contact" element={<Contact />} />
                              <Route path="/areas-we-cover/*" element={<Navigate to="/about" replace />} />
                              <Route path="/recycling-services/*" element={<Navigate to="/services" replace />} />
                              <Route path="*" element={<Home />} />
                        </Routes>
                        <Footer />
                  </MyContext.Provider>
            </>
      )
}