import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Row, Col, Dropdown, Drawer, Alert } from 'antd'
import Logo from '../../images/logo.png'
import { FaEllipsisV } from 'react-icons/fa'


export function Header() {

      const navigate = useNavigate()

      const [ open, setOpen ] = useState(false)
      const [ childrenDrawer0, setChildrenDrawer0 ] = useState(false)
      const [ childrenDrawer1, setChildrenDrawer1 ] = useState(false)
      const [ childrenDrawer2, setChildrenDrawer2 ] = useState(false)
      const [ childrenDrawer3, setChildrenDrawer3 ] = useState(false)
      const [ childrenDrawer4, setChildrenDrawer4 ] = useState(false)
      const [ childrenDrawer5, setChildrenDrawer5 ] = useState(false)

      const onClose = () => {
            setOpen(false)
      }

      const showDrawer = () => {
            setOpen(true)
      }

      const showChildrenDrawer = (i) => {
            switch (i) {
                  case 0: setChildrenDrawer0(true); break
                  case 1: setChildrenDrawer1(true); break
                  case 2: setChildrenDrawer2(true); break
                  case 3: setChildrenDrawer3(true); break
                  case 4: setChildrenDrawer4(true); break
                  case 5: setChildrenDrawer5(true); break
                  default: // code block
            }
      }

      const onChildrenDrawerClose = (i) => {
            switch (i) {
                  case 0: setChildrenDrawer0(false); break
                  case 1: setChildrenDrawer1(false); break
                  case 2: setChildrenDrawer2(false); break
                  case 3: setChildrenDrawer3(false); break
                  case 4: setChildrenDrawer4(false); break
                  case 5: setChildrenDrawer5(false); break
                  default: // code block
            }
      }

      const items = [
            {
                  key: '1',
                  label: (
                        <div onClick={() => navigate("/")}>ORDER A SKIP</div>
                  ),
            },
            {
                  key: '2',
                  label: (

                        <div onClick={showDrawer}>FAQ & SUPPORT</div>

                  ),
            },
            {
                  key: '3',
                  label: (
                        <div onClick={() => navigate("/services")}>OUR SERVICES</div>
                  ),
            },
            {
                  key: '4',
                  label: (
                        <div onClick={() => navigate("/about")}>ABOUT US</div>
                  ),
            },
            {
                  key: '5',
                  label: (
                        <div onClick={() => navigate("/contact")}>CONTACT US</div>
                  ),
            }
      ]

      const questionDrawerWidth = window.innerWidth > 900 ? 600 : window.innerWidth * 0.9
      const answerDrawerWidth = window.innerWidth > 900 ? 600 : window.innerWidth * 0.9

      return (
            <>
                  <div id="header">

                        <Row justify="center">

                              <Col xs={22} sm={22} md={22} lg={20} xxl={18}>

                                    <Row align="middle" >

                                          <Col span={12}>
                                                <img className="logo-header clickable" src={Logo} alt={"MJChurch Logo"} onClick={() => navigate("/")} />
                                          </Col>

                                          <Col style={{ textAlign: "right" }} span={12}>

                                                <Dropdown menu={{ items }} placement="bottomRight">
                                                      <FaEllipsisV className="header-menu-toggle" />
                                                </Dropdown>

                                          </Col>

                                    </Row>

                              </Col>

                        </Row>

                  </div>


                  <Drawer title="Frequently asked questions" width={questionDrawerWidth} closable onClose={onClose} open={open}>

                        <div className="clickable" onClick={() => showChildrenDrawer(0)}>
                              <p>What size skip will I need</p>
                        </div>
                        <Drawer
                              title="Answer"
                              width={answerDrawerWidth}
                              closable={true}
                              onClose={() => onChildrenDrawerClose(0)}
                              open={childrenDrawer0}
                        >
                              <Alert style={{ fontWeight: "300", fontSize: "110%" }} message="All of our sizing information is provided on the quote page before you commit to ordering a skip." type="success" />
                        </Drawer>

                        <div className="clickable" onClick={() => showChildrenDrawer(1)}>
                              <p>What can I put in my skip?</p>
                        </div>
                        <Drawer
                              title="Answer"
                              width={answerDrawerWidth}
                              closable={true}
                              onClose={() => onChildrenDrawerClose(1)}
                              open={childrenDrawer1}
                        >
                              <Alert style={{ fontWeight: "300", fontSize: "110%" }} message="All of the permitted and prohibited items are clearly listed on the quote page. Just click on the 'info' icon to see the full list." type="success" />
                        </Drawer>

                        <div className="clickable" onClick={() => showChildrenDrawer(2)}>
                              <p>How do I load my skip?</p>
                        </div>
                        <Drawer
                              title="Answer"
                              width={answerDrawerWidth}
                              closable={true}
                              onClose={() => onChildrenDrawerClose(2)}
                              open={childrenDrawer2}
                        >
                              <Alert style={{ fontWeight: "300", fontSize: "110%" }} message="To ensure maximum value from your skip, plan the contents: 1) Dismantle bulky items 2) Don't overfill the skip or have large bulky items protruding from the sides. Overfilled skips could result in the refusal of collection as we are limited by weights and safety on the roads." type="success" />
                        </Drawer>

                        <div className="clickable" onClick={() => showChildrenDrawer(3)}>
                              <p>What size truck delivers my skip?</p>
                        </div>
                        <Drawer
                              title="Answer"
                              width={window.innerWidth > 900 ? 600 : window.innerWidth - 150}
                              closable={true}
                              onClose={() => onChildrenDrawerClose(3)}
                              open={childrenDrawer3}
                        >
                              <Alert style={{ fontWeight: "300", fontSize: "110%" }} message="Our 2,4,6 and 10 yard skips are delivered by a full size 4-wheel 18-tonne skip truck." type="success" />
                        </Drawer>

                        <div className="clickable" onClick={() => showChildrenDrawer(4)}>
                              <p>How long can I keep my skip?</p>
                        </div>
                        <Drawer
                              title="Answer"
                              width={answerDrawerWidth}
                              closable={true}
                              onClose={() => onChildrenDrawerClose(4)}
                              open={childrenDrawer4}
                        >
                              <Alert style={{ fontWeight: "300", fontSize: "110%" }} message="We hire our skips out on a two-week basis. In certain circumstances it is possible to extend this period but not without prior arrangement. Once the two-week period is up, we reserve the right to collect the skip without notice." type="success" />
                        </Drawer>

                        <div className="clickable" onClick={() => showChildrenDrawer(5)}>
                              <p>Do I need a permit from the council?</p>
                        </div>
                        <Drawer
                              title="Answer"
                              width={answerDrawerWidth}
                              closable={true}
                              onClose={() => onChildrenDrawerClose(5)}
                              open={childrenDrawer5}
                        >
                              <Alert style={{ fontWeight: "300", fontSize: "110%" }} message="If your skip is going to be located on a public road or any council-owned property (including grass verges), by law you will require a skip hire permit. We will arrange this for you as part of the skip hire process; simply indicate on your order if a permit is required." type="success" />
                        </Drawer>

                  </Drawer>

            </>

      )
}
