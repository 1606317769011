import { Row, Col } from 'antd'
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"

export function About() {

      return (

            <>

                  <Helmet>
                        <meta charSet="utf-8" />
                        <title>About Us | MJ Church Plant Limited</title>
                        <link rel="canonical" href="https://www.mjchurchskiphire.co.uk/about" />
                        <meta name="description" content="MJ Church are the No 1 Local Skip Hire company. For over 30 years we've been servicing our loyal local customers with an excellent 'One-Stop Shop' skip hire service." />
                  </Helmet>

                  <div id="static-content-wrapper">

                        <Row justify="center">

                              <Col xs={22} sm={22} md={22} lg={20} xxl={18}>

                                    <h1>About us.</h1>

                                    <h2>Who we are</h2>
                                    <p>Since MJ Church Limited was first established in 1974, our company has gone from strength to strength to meet increasing customer demand. </p>
                                    <p>During our four decades of operation, we have seen a considerable shift towards recycling and people choosing to lead a more sustainable lifestyle or business. Paying attention to this, we have adapted our services accordingly which has allowed us to expand and prosper.</p>
                                    <p>We have invested in skilled staff, state-of-the-art machinery and purpose-built facilities to divert as much waste away from landfill as possible and reduce our environmental impact. We travel directly to our customers to collect their waste with our modern fleet of vehicles and skips, helping to reduce carbon footprint and boost efficiency. </p>

                                    <h2>Areas we cover</h2>
                                    <p>Our skip hire services include coverage for Chippenham, Swindon, Bath, Bristol and surrounding areas.</p>
                                    <p>Enter your postcode <Link to={"/"}>over here</Link> to coverage and start an online quote.</p>

                              </Col>

                        </Row>

                  </div>

            </>
      )
}