import { Button, Radio, Form, Input, Space, message, Alert } from 'antd'
import { saveOrder } from "../libs/helper"
import { useState } from "react"
import ReactGA from "react-ga4"
const { TextArea } = Input


export function ContactForm() {

      const [ value, setValue ] = useState(1)
      const onChange = (e) => {
            console.log('radio checked', e.target.value)
            setValue(e.target.value)
      }

      let [ disabledButton, setDisabledButton ] = useState(false)
      let [ formComplete, setFormComplete ] = useState(false)

      async function onFinish(values) {

            //Send an event to GA4
            ReactGA.event('form_submit', {
                  items: [ {
                        'id': sessionStorage.getItem("_MJC_SESSION"),
                        'type': 'contact_form',
                        'quantity': 1
                  } ]
            })


            setDisabledButton(true)
            console.log('Success:', values)
            let newData = { id: sessionStorage.getItem("_MJC_SESSION") }
            values = { ...values, ...newData }
            let result = await saveOrder('/form/contact', values)
            setDisabledButton(false)
            console.log(result)
            if (result.status === "success") {
                  setFormComplete(true)
                  window.scrollTo(0, 0)
                  // message.success("Thank you for getting in touch. We'll be in touch shortly.")
            } else {

                  message.error("Something went wrong. Please try again.")
            }
      }

      const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo)
      }

      if (!formComplete) {

            return (
                  <Form
                        style={{ marginTop: "50px" }}
                        name="contactForm"
                        layout="vertical"
                        wrapperCol={{ span: 18 }}
                        initialValues={{
                              remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        scrollToFirstError={{ behavior: 'smooth' }}
                  >
                        <Form.Item
                              label="Name"
                              name="name"
                              rules={[ { required: true, message: 'Please enter your name.', } ]}
                        >
                              <Input />
                        </Form.Item>

                        <Form.Item
                              label="Telephone"
                              name="telephone"
                              rules={[ { required: true, message: 'Please enter your name.', } ]}
                        >
                              <Input />
                        </Form.Item>

                        <Form.Item
                              label="Email"
                              name="email"
                        >
                              <Input />
                        </Form.Item>

                        <Form.Item
                              label="Postcode"
                              name="postcode"
                        >
                              <Input />
                        </Form.Item>

                        <Form.Item
                              style={{ margin: "45px 0" }}
                              label="Service interest"
                              name="service"
                              rules={[ { required: true, message: 'Please select an area of interest', } ]}
                        >
                              <Radio.Group onChange={onChange} value={value}>
                                    <Space direction="vertical">
                                          <Radio value={"skip-hire"} style={{ marginTop: "15px" }} >Skip Hire</Radio><br />
                                          <Radio value={"waste-management"}>Waste Management</Radio><br />
                                          <Radio value={"recycling-services"}>Recycling Services</Radio><br />
                                          <Radio value={"scrap-metal"}>Scrap Metal</Radio><br />
                                          <Radio value={"other"}>Other</Radio>
                                    </Space>
                              </Radio.Group>
                        </Form.Item>

                        <Form.Item
                              label="Your message"
                              name="message"
                        >
                              <TextArea rows={6} />
                        </Form.Item>

                        <Form.Item
                        >
                              <Button type="primary" size="large" disabled={disabledButton} htmlType="submit">
                                    Submit form
                              </Button>
                        </Form.Item>
                  </Form>
            )

      } else {
            return (<Alert style={{ marginTop: "25px", width: "75%" }} message="Thank you for getting in touch. We'll be in touch shortly" type="success" />)
      }
}