import { useState, useEffect, useContext } from "react"
import { getQuote } from "../libs/helper"
import { Row, Col, Button, Divider, message, Modal, Alert } from "antd"
import { useNavigate } from "react-router-dom"
import lifter from '../images/lifter.png'
import skiptruck from '../images/skiptruck.png'
import { RiEditFill, RiQuestionLine } from "react-icons/ri"
import { Loading } from "../components/Loading"
import ReactGA from "react-ga4"
import { v4 as uuid } from "uuid"
import { MyContext } from "../App"

const skipData = {
      s4: {
            description: "1.4m Wide x 0.9m High x 1.8m Long",
            bullets: [ "Our smallest skip", "Maintenance work", "Small domestic projects" ]
      },
      s6: {
            description: "1.5m Wide x 1.2m High x 2.6m Long",
            bullets: [ "Our most popular skip", "Small scale home renovations", "Garden & Landscaping waste" ]
      },
      s8: {
            description: "1.8m Wide x 1.4m High x 3.4m Long",
            bullets: [ "82% more space than a 6 yard", "Small scale construction work", "Demolition projects" ]
      },
      s10: {
            description: "1.8m Wide x 1.5 High x 3.66m Long",
            bullets: [ "Capacity of 2x Transit vans", "Maintenance work", "Domestic repair projects" ]
      },
      s12: {
            description: "1.8m Wide x 1.7m High x 4.0m Long",
            bullets: [ "Our largest skip", "Major House Renovations", "Bulky waste" ]
      }
}


export function Quote({ address, resetFunc }) {

      const navigate = useNavigate()
      const [ messageApi, contextHolder ] = message.useMessage()
      let { AppData, setAppData } = useContext(MyContext)

      const [ isInertFalseModalOpen, setIsInertFalseModalOpen ] = useState(false)
      const [ isInertTrueModalOpen, setIsInertTrueModalOpen ] = useState(false)
      const [ isOnroadFalseModalOpen, setIsOnroadFalseModalOpen ] = useState(false)
      const [ isOnroadTrueModalOpen, setIsOnroadTrueModalOpen ] = useState(false)

      const showModal = (modal) => {
            switch (modal) {
                  case 'InertFalse':
                        setIsInertFalseModalOpen(true)
                        break
                  case 'InertTrue':
                        setIsInertTrueModalOpen(true)
                        break
                  case 'OnroadFalse':
                        setIsOnroadFalseModalOpen(true)
                        break
                  case 'OnroadTrue':
                        setIsOnroadTrueModalOpen(true)
                        break
                  default:
                  // code block
            }

      }

      const handleCancel = (modal) => {
            switch (modal) {
                  case 'InertFalse':
                        setIsInertFalseModalOpen(false)
                        break
                  case 'InertTrue':
                        setIsInertTrueModalOpen(false)
                        break
                  case 'OnroadFalse':
                        setIsOnroadFalseModalOpen(false)
                        break
                  case 'OnroadTrue':
                        setIsOnroadTrueModalOpen(false)
                        break
                  default:
                  // code block
            }

      }

      let id = sessionStorage.getItem("_MJC_SESSION")

      // Set state variables
      let [ price, setPrice ] = useState(0)
      let [ netPrice, setNetPrice ] = useState(0)
      let [ isLoading, setIsLoading ] = useState(true)
      let [ isPriceLoading, setIsPriceLoading ] = useState(true)
      let [ rfqStatus, setRfqStatus ] = useState(true)
      let [ postcode ] = useState(address.postcode)
      let [ selection, setSelection ] = useState({ id, postcode, skipsize: 6, inert: false, onroad: false, address })
      let [ description, setDescription ] = useState("")
      let [ bullets, setBullets ] = useState([])

      const rfqError = <>
            <h2>Sorry. Our website doesn't currently service your location.</h2>
            <p>However, we have a dedicated team who are able to help.</p>
            <p>Give them a call during office hours on {AppData.telephone}</p>
            <Divider />
            <p style={{ marginTop: "-5px", fontStyle: "italic" }}>Want to check another postcode? <Button onClick={() => resetFunc()} type="link">Click here</Button></p>
      </>

      const priceBlock = <div style={{ marginTop: AppData?.hideFooter ? "-25px" : "0" }}>
            <h1>£{price.toFixed(2)}</h1>
            <p style={{ marginTop: "-30px" }}>Price inc vat. £{netPrice.toFixed(2)} exc vat.</p>
            <Button style={{ marginTop: "5px" }} type="primary" size="large" block onClick={() => navigate("/order")}>ORDER NOW <img style={{ height: "22px", marginLeft: "5px", marginTop: "-3px" }} src={lifter} alt="lifter iconmark" /></Button>
      </div>

      function userSelect(data) {

            // Inert not allowed > 6 yard
            if (data.skipsize > 6 && data.inert) {
                  messageApi.open({
                        type: 'error',
                        content: 'Sorry, The largest skip for soil and rubble is our 6 Yard.',
                        duration: 5,
                  })
                  data = { ...data, skipsize: 6 }
            }

            //Set the selection
            setSelection(data)

            return

      }

      // Handle skipsize dynamic content
      useEffect(() => {

            const data = skipData[ `s${selection.skipsize}` ]
            setDescription(data.description)

            const bullet_ul = data.bullets.map((bullet) =>
                  <li key={uuid()}>
                        {bullet}
                  </li>
            )

            setBullets(bullet_ul)


      }, [ selection ])

      const rfqSuccess = <>

            <Row>

                  <Col span={24}><p style={{ fontWeight: 700, fontSize: "24px", fontFamily: 'Urbanist', letterSpacing: "0px", marginBottom: "10px" }}>Select your skip size</p></Col>

            </Row>

            <Row gutter={16}>


                  <Col flex={1}><div className={`toggle gutter-row ${selection.skipsize === 4 ? 'toggle-active' : null}`} onClick={() => userSelect({ ...selection, skipsize: 4 })}>XS</div></Col>
                  <Col flex={1}><div className={`toggle gutter-row ${selection.skipsize === 6 ? 'toggle-active' : null}`} onClick={() => userSelect({ ...selection, skipsize: 6 })}>S</div></Col>
                  <Col flex={1}><div className={`toggle gutter-row ${selection.skipsize === 8 ? 'toggle-active' : null}`} onClick={() => userSelect({ ...selection, skipsize: 8 })}>M</div></Col>
                  <Col flex={1}><div className={`toggle gutter-row ${selection.skipsize === 10 ? 'toggle-active' : null}`} onClick={() => userSelect({ ...selection, skipsize: 10 })}>L</div></Col>
                  <Col flex={1}><div className={`toggle gutter-row ${selection.skipsize === 12 ? 'toggle-active' : null}`} onClick={() => userSelect({ ...selection, skipsize: 12 })}>XL</div></Col>

            </Row>

            <Row type="flex" style={{ border: "1px dashed rgba(0,0,0,0.2)", backgroundColor: "rgba(0,0,0,0.02)" }}>

                  <Col xs={0} sm={6}>
                        <div className="quote-summary">
                              <img src={skiptruck} alt="" style={{ height: "120px", marginTop: "10px" }} />
                              <p style={{ textAlign: "center", marginTop: "10px", fontSize: "18px" }}>{postcode} <RiEditFill onClick={() => resetFunc()} className="clickable" style={{ marginLeft: "5px", fontSize: "18px", display: "inline-block", verticalAlign: "-3px", color: "#AEE0F4" }} /></p>
                        </div>
                  </Col>

                  <Col xs={24} sm={10}>
                        <div className="quote-summary">
                              <h1>The {selection.skipsize} Yard</h1>
                              <p style={{ marginTop: "-30px" }}>{description}</p>

                              <ul style={{ marginLeft: "-13px", marginTop: "0" }}>
                                    {bullets}
                              </ul>
                        </div>
                  </Col>

                  <Col xs={24} sm={8}>
                        <div className="quote-summary">
                              {isPriceLoading ? <Loading /> : priceBlock}
                        </div>
                  </Col>

            </Row>

            <Row>
                  <Col flex={AppData?.hideFooter ? "100%" : "40%"}>
                        <Row>
                              <Col flex="50%"><div className={`toggle toggle-slim gutter-row ${selection.inert === false ? 'toggle-active' : null}`} onClick={() => userSelect({ ...selection, inert: false })}>MIXED WASTE <RiQuestionLine className="help-icon" onClick={() => showModal('InertFalse')} /></div></Col>
                              <Col flex="50%"><div className={`toggle toggle-slim gutter-row ${selection.inert === true ? 'toggle-active' : null}`} onClick={() => userSelect({ ...selection, inert: true })}>SOIL & STONE
                                    <RiQuestionLine className="help-icon" onClick={() => showModal('InertTrue')} /></div></Col>
                        </Row>
                  </Col>
                  <Col flex={AppData?.hideFooter ? "0%" : "20%"}></Col>
                  <Col flex={AppData?.hideFooter ? "100%" : "40%"}>
                        <Row>
                              <Col flex="50%"><div className={`toggle toggle-slim gutter-row ${selection.onroad === false ? 'toggle-active' : null}`} onClick={() => userSelect({ ...selection, onroad: false })}>OFF-ROAD
                                    <RiQuestionLine className="help-icon" onClick={() => showModal('OnroadFalse')} /></div></Col>
                              <Col flex="50%"><div className={`toggle toggle-slim gutter-row ${selection.onroad === true ? 'toggle-active' : null}`} onClick={() => userSelect({ ...selection, onroad: true })}>ON-ROAD
                                    <RiQuestionLine className="help-icon" onClick={() => showModal('OnroadTrue')} /></div></Col>
                        </Row>
                  </Col>

            </Row>

            <Modal title="Mixed waste" open={isInertFalseModalOpen} footer={null} onCancel={() => handleCancel('InertFalse')}>
                  <p>This option is for all types of non-hazardous, mixed recyclable waste:</p>
                  <Alert message="Domestic" type="success" />
                  <Alert message="Trade" type="success" />
                  <Alert message="Builders" type="success" />
                  <Alert message="Industrial" type="success" />
                  <Alert message="Garden / Inert" type="success" />
                  <p style={{ marginTop: "25px" }}>These materials are subject to additional charges:</p>
                  <Alert message="Asbestos" type="error" />
                  <Alert message="Batteries" type="error" />
                  <Alert message="Oil & chemicals" type="error" />
                  <Alert message="Residual paint tins" type="error" />
                  <Alert message="Computer Monitors" type="error" />
                  <Alert message="TVs, Printers, Microwaves" type="error" />
                  <Alert message="Fridges & Freezers" type="error" />
                  <Alert message="Flourescent tubes" type="error" />
                  <Alert message="Pressure Gas Bottles" type="error" />
                  <Alert message="Tyres" type="error" />
                  <Alert message="Polystyrene" type="error" />
                  <Alert message="Wood with bitumen" type="error" />
                  <Alert message="Animal carcasses" type="error" />
                  <Alert message="Industrial" type="error" />
                  <Alert message="Surgical waste" type="error" />
                  <Alert message="Solar panels" type="error" />
                  <Alert message="Railway sleepers" type="error" />
                  <p style={{ marginTop: "25px" }}>Should any of the materials or substances be found in our skips, we reserve the right to charge for the additional cost of disposal of such materials and/or the option to return materials to the site/originating address.</p>
            </Modal>

            <Modal title="Hardcore" open={isInertTrueModalOpen} footer={null} onCancel={() => handleCancel('InertTrue')}>
                  <p>The skip must be loaded 100% with:</p>
                  <Alert message="Stone" type="success" />
                  <Alert message="Concrete" type="success" />
                  <Alert message="Builders" type="success" />
                  <Alert message="Bricks" type="success" />
                  <Alert message="Gravel" type="success" />
                  <Alert message="Soil" type="success" />
                  <p style={{ marginTop: "25px" }}>Please be advised, if the load is contaminated with any other type of waste, additional charges will apply.</p>
            </Modal>

            <Modal title="Off-road" open={isOnroadFalseModalOpen} footer={null} onCancel={() => handleCancel('OnroadFalse')}>
                  <p>If your skip is going to be located on a public road or any council-owned property (including grass verges), by law you will require a skip hire permit. We will arrange this for you as part of the skip hire process; simply indicate on your order if a permit is required.</p>
                  <Alert message="This option doesn't includes us paying for and organising a permit" type="error" />
            </Modal>

            <Modal title="On-road" open={isOnroadTrueModalOpen} footer={null} onCancel={() => handleCancel('OnroadTrue')}>
                  <p>If your skip is going to be located on a public road or any council-owned property (including grass verges), by law you will require a skip hire permit. We will arrange this for you as part of the skip hire process; simply indicate on your order if a permit is required.</p>
                  <Alert message="This option includes us paying for and organising a permit" type="success" />
            </Modal>

      </>

      const rfqResponse = rfqStatus === true ? rfqSuccess : rfqError




      useEffect(() => {

            (async () => {

                  if (window.innerWidth <= 768) {
                        setAppData({ hideFooter: true })
                  }

                  setIsPriceLoading(true)

                  let quote = await getQuote(selection, address)

                  setIsLoading(false)

                  const postcode = selection.postcode
                  const postcode_arr = postcode.split(" ")
                  const zone = postcode_arr[ 0 ]

                  if (quote.status === "error") {

                        // Error #1 : Zone not in service
                        if (quote.result.includes("Zone not in service")) {

                              //Send an event to GA4
                              ReactGA.event('zone_error', {
                                    items: [ {
                                          'id': selection.id,
                                          'zone': zone,
                                          'quantity': 1
                                    } ]
                              })

                              setRfqStatus(false)
                        }

                  } else {



                        //Send an event to GA4
                        ReactGA.event('select_item', {
                              items: [ {
                                    'id': selection.id,
                                    'zone': zone,
                                    'skipsize': selection.skipsize,
                                    'inert': selection.inert,
                                    'onroad': selection.onroad,
                                    'price': quote.result.price.gross,
                                    'quantity': 1
                              } ]
                        })

                        setIsPriceLoading(false)
                        setNetPrice(quote.result.price.net)
                        setPrice(quote.result.price.gross)

                  }


            })()
            // eslint-disable-next-line
      }, [ selection ])


      return (

            <>
                  <Row justify="center">

                        <Col xs={23} lg={{ span: 14, offset: 9 }} xl={{ span: 17, offset: 6 }}>

                              <div id="quote-card" style={rfqStatus === false ? { borderTop: "3px solid red" } : null}>

                                    {isLoading ? <Loading /> : rfqResponse}

                                    {contextHolder}

                              </div>
                        </Col >
                  </Row >


            </>
      )






}