import { Row, Col } from 'antd'

export function PaymentProblem() {

      return (
            <>
                  <Row justify="center">
                        <Col xs={22} sm={22} md={22} lg={20} xxl={18}>
                              <p>Payment shit the bed</p>
                        </Col>
                  </Row>

            </>
      )
}