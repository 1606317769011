import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import "antd/dist/antd.min.css"
import './style.css'
import App from './App'

// Ensures router changes force a scrollTop
import ScrollTop from "./libs/scroll"

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
      <BrowserRouter>
            <ScrollTop />
            <App />
      </BrowserRouter>
)
