import { useEffect } from "react"
import { getOrder } from "../../libs/helper"
import { message } from "antd"
import { useNavigate, useSearchParams } from "react-router-dom"
import ReactGA from "react-ga4"

export function PaymentNotify() {

      const navigate = useNavigate()

      const [ searchParams ] = useSearchParams()

      async function onSuccess(id) {

            // Get data from database
            let order = await getOrder(id, { ga4: true })

            if (order.status === "success") {

                  //Send an event to GA4
                  ReactGA.event('purchase', {
                        currency: 'GBP',
                        transaction_id: id,
                        value: order.result[ 0 ].margin_nominal,
                        items: [ {
                              item_id: id,
                              item_name: 'Skip Hire',
                              price: order.result[ 0 ].price_gross,
                              quantity: 1,
                              item_category: order.result[ 0 ].skip_size,
                              item_category2: order.result[ 0 ].inert_material === 1 ? 'inert' : 'mixed',
                              item_category3: order.result[ 0 ].road_licence === 1 ? 'on-road' : 'off-road',
                        } ]
                  })

            }

            navigate('/complete', { replace: true })

      }

      function onError(err) {

            let msg = ''

            switch (err) {
                  case "NOTAUTHED": msg = "Your bank has declined this transaction. Please use a different card"; break
                  case "ABORT": msg = "You aborted the payment stage. Please check order details and try again"; break
                  case "PENDING": msg = "Order pending. Please wait"; break
                  case "REJECTED": msg = "Your bank has declined this transaction. Please use a different card"; break
                  case "ERROR": msg = "An error occurred when talking to the bank. Please try again later"; break
                  default: msg = "An unknown error has occurred"
            }

            message.error(msg)

            navigate('/order', { replace: true })

      }

      useEffect(() => {

            const status = searchParams.get("status")
            const id = sessionStorage.getItem("_MJC_SESSION")

            status === "OK" ? onSuccess(id) : onError(status)

            // eslint-disable-next-line
      }, [])

      return (null)


}