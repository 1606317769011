import { Row, Col, Divider } from 'antd'
import { Helmet } from "react-helmet"
import { useContext } from 'react'
import { MyContext } from "../../../App"
import { Link } from "react-router-dom"
import { ContactForm } from "../../../components/ContactForm"
import { FiPhone } from "react-icons/fi"

export function Contact() {

      let { AppData } = useContext(MyContext)

      return (

            <>

                  <Helmet>
                        <meta charSet="utf-8" />
                        <title>Contact Us | MJ Church Plant Limited</title>
                        <link rel="canonical" href="https://www.mjchurchskiphire.co.uk/contact" />
                        <meta name="description" content="MJ Church are the No 1 Local Skip Hire company. For over 30 years we've been servicing our loyal local customers with an excellent 'One-Stop Shop' skip hire service." />
                  </Helmet>

                  <div id="static-content-wrapper">

                        <Row justify="center">

                              <Col xs={22} sm={22} md={22} lg={20} xxl={18}>

                                    <Row>
                                          <Col xs={24} lg={12}>
                                                <h1>Say hello.</h1>
                                                <p>Looking for a price for a skip? Realtime prices <Link to={"/"}>here</Link></p>
                                                <p>For everything else fill out the form below or give us a call.</p>
                                                <ContactForm />
                                          </Col>

                                          <Col xs={24} lg={12}>
                                                <div style={{ padding: "25px 50px 50px 50px", backgroundColor: "var(--light-yellow)" }}>
                                                      <h2><FiPhone style={{ marginRight: "10px", verticalAlign: "-5px" }} /> {AppData.telephone}</h2>
                                                      <Divider />
                                                      <h3>Office Location</h3>
                                                      <p>Allington Transfer Station<br />Bristol Road<br />Allington<br />Chippenham<br />SN14 6LZ</p>
                                                </div>
                                          </Col>
                                    </Row>


                              </Col>

                        </Row>

                  </div>

            </>
      )
}