import { MyContext } from '../App'
import { useContext } from "react"
import mapStyles from '../libs/mapStyles.json'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import MapMarker from '../images/marker-30.png'

export function Map({ zoom, center, marker, markerPosition }) {

      const { AppData } = useContext(MyContext)

      // Calculate map height dynamically
      const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      const headerHeight = 111
      const mapHeight = viewportWidth >= 768 ? (viewportHeight - headerHeight) * 0.85 : (viewportHeight - headerHeight) * 0.40

      const MyMapComponent = withScriptjs(withGoogleMap((props) =>
            <GoogleMap
                  defaultZoom={12}
                  defaultCenter={{ lat: 51.39590, lng: -2.37186 }}
                  zoom={zoom}
                  center={center}
                  options={{
                        disableDefaultUI: true,
                        clickableIcons: false,
                        fullscreenControl: false,
                        draggable: false,
                        styles: mapStyles
                  }}
            >
                  {props.isMarkerShown && <Marker position={markerPosition} icon={MapMarker} />}
            </GoogleMap>
      ))

      return (
            <MyMapComponent
                  isMarkerShown={marker}
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${AppData.google_maps_api_key}&v=3.exp&libraries=geometry,drawing,places`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `${mapHeight}px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
            />
      )
}