import { useEffect, useState, useContext } from "react"
import { MyContext } from '../App'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { Row, Col, Form, Input } from "antd"
import { AddressFinder } from "@ideal-postcodes/address-finder"
import { Quote } from "./Quote"


export function OverMap({ updateFunc }) {

      const { AppData } = useContext(MyContext)

      let [ postcode, setPostcode ] = useState(null)
      let [ address, setAddress ] = useState({})

      // Not sure why below required but it works ;)
      let [ v, setV ] = useState(0)

      // This useEffect is ridiculous, but required for when somebody returns to enter postcode for 2+ times
      useEffect(() => {

            // eslint-disable-next-line
            const controller = AddressFinder.setup({
                  inputField: "#postcodeInput",
                  apiKey: AppData.ideal_postcodes_api_key,
                  titleizePostTown: true,
                  outputFields: {
                        line_1: "#address_1",
                        line_2: "#address_2",
                        line_3: "#address_3",
                        post_town: "#town",
                        county: "#county",
                        postcode: "#postcode",
                        longitude: "#longitude",
                        latitude: "#latitude"
                  },
                  onAddressPopulated: address => {
                        form.submit()
                  }
            })

            // eslint-disable-next-line
      }, [ v ])


      useEffect(() => {

            // eslint-disable-next-line
            const controller = AddressFinder.setup({
                  inputField: "#postcodeInput",
                  apiKey: AppData.ideal_postcodes_api_key,
                  titleizePostTown: true,
                  outputFields: {
                        line_1: "#address_1",
                        line_2: "#address_2",
                        line_3: "#address_3",
                        post_town: "#town",
                        county: "#county",
                        postcode: "#postcode",
                        longitude: "#longitude",
                        latitude: "#latitude"
                  },
                  onAddressPopulated: address => {
                        form.submit()
                  }
            })

            // eslint-disable-next-line
      }, [])

      const [ form ] = Form.useForm()

      const onFormFinish = (values) => {

            console.log(values)

            setAddress(values)

            updateFunc(15, parseFloat(values.latitude), parseFloat(values.longitude), true, true)
            setPostcode(values.postcode)

      }

      const resetFunc = (zoom, lat, lng) => {
            setPostcode('')
            updateFunc()
            setV(v = 1 - v)
      }

      if (postcode) {

            return (
                  <>
                        <div id="over-map-quotebox">
                              <Quote address={address} resetFunc={resetFunc} />
                        </div>
                  </>
            )
      }

      else {

            return (
                  <>
                        <div id="over-map-lookup">

                              <Row justify="center">

                                    <Col xs={22} lg={10}>

                                          <Input
                                                data-lpignore={true}
                                                autoComplete="off"
                                                id="postcodeInput"
                                                prefix={<FaMapMarkerAlt style={{ fontSize: "2rem", color: 'rgba(255, 255, 255, 0.35)' }} />}
                                                style={{
                                                      width: "100%",
                                                      borderRadius: '50vh',
                                                      backgroundColor: 'rgba(255, 206, 0, 1)',
                                                      border: 0,
                                                      outline: 0,
                                                      padding: '5px 30px'
                                                }}
                                                placeholder="Postcode"
                                          />

                                    </Col>

                              </Row>

                        </div>

                        <Form form={form} onFinish={onFormFinish} style={{ display: 'none' }}>
                              <Form.Item hidden name="address_1"><Input id="address_1" type="text" /></Form.Item>
                              <Form.Item name="address_2"><Input id="address_2" type="text" /></Form.Item>
                              <Form.Item name="address_3"><Input id="address_3" type="text" /></Form.Item>
                              <Form.Item name="town"><Input id="town" type="text" /></Form.Item>
                              <Form.Item name="county"><Input id="county" type="text" /></Form.Item>
                              <Form.Item name="postcode"><Input id="postcode" type="text" /></Form.Item>
                              <Form.Item name="longitude"><Input id="longitude" type="text" /></Form.Item>
                              <Form.Item name="latitude"><Input id="latitude" type="text" /></Form.Item>
                        </Form>
                  </>
            )

      }


}