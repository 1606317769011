import { useLayoutEffect, useEffect, useState } from 'react'
import { Row, Col, Divider, Form, Input, Button, Checkbox, DatePicker, message, Alert } from 'antd'
import { getOrder, saveOrder, registerPayment, getHolidays } from "../../libs/helper"
import { useNavigate } from "react-router-dom"
import { Loading } from "../../components/Loading"
import { MdFace, MdOutlinePayment } from 'react-icons/md'
import { HiOutlineCube } from 'react-icons/hi'
import { TiArrowBackOutline } from "react-icons/ti"
import { GrDeliver } from 'react-icons/gr'
import moment from "moment"
const { TextArea } = Input

export function Order() {

      const navigate = useNavigate()

      const [ form ] = Form.useForm()

      let [ isLoading, setIsLoading ] = useState(true)
      let [ isBilling, setIsBilling ] = useState(true)
      let [ tc, setTC ] = useState(false)

      let [ buttonText, setButtonText ] = useState("loading ...")
      let [ disableButton, setDisableButton ] = useState(false)

      let [ price, setPrice ] = useState(false)
      let [ data, setData ] = useState('loading ..')

      let [ bankHolidays, setBankHolidays ] = useState([])

      useEffect(() => {

            (async () => {
                  let holidays = await getHolidays()
                  setBankHolidays(holidays)
            })()

      }, [])

      useLayoutEffect(() => {

            (async () => {

                  if (sessionStorage.getItem("_MJC_SESSION") !== null) {

                        // Get data from database
                        let order = await getOrder(sessionStorage.getItem("_MJC_SESSION"))

                        if (order.status === "success") {

                              // Set data locally to state
                              setIsLoading(false)
                              setData(order.result)
                              setPrice(order.result[ 0 ].price_gross)

                              // Set form fields
                              form.setFieldsValue({
                                    first_name: order.result[ 0 ].customer_first_name,
                                    last_name: order.result[ 0 ].customer_last_name,
                                    telephone: order.result[ 0 ].customer_telephone,
                                    email: order.result[ 0 ].customer_email,
                                    company_name: order.result[ 0 ].customer_company_name,
                                    address1: order.result[ 0 ].delivery_address_1,
                                    address2: order.result[ 0 ].delivery_address_2,
                                    town: order.result[ 0 ].delivery_address_town,
                                    county: order.result[ 0 ].delivery_address_county,
                                    postcode: order.result[ 0 ].delivery_address_postcode,
                                    order_notes: order.result[ 0 ].delivery_notes
                              })

                        } else {
                              // API error
                              console.log(order.result)
                              message.error("Your order session has expired. Please re-enter your postcode for the latest prices.")
                              navigate('/', { replace: true })
                        }
                  } else {
                        // No sessionStorage exists
                        console.log('no sessionStorage()')
                        message.error("Your order session has expired. Please re-enter your postcode for the latest prices.")
                        navigate('/', { replace: true })
                  }

            })()
            // eslint-disable-next-line
      }, [])

      // Set price on button once received
      useEffect(() => {
            setButtonText(`Pay £${price}`)
      }, [ price ])


      // When form passes validation, register payment with Sagepay, then redirect
      const onFinish = async (values) => {

            // Change button state
            setButtonText(`Please wait ..`)
            setDisableButton(true)

            // Insert session id
            values.id = sessionStorage.getItem("_MJC_SESSION")

            // Format delivery date
            values.date = moment(values.date).format("YYYY-MM-DD")

            let result = await saveOrder('/orders/update', values)
            console.log(result)

            if (result.status === "success") {

                  // Build object
                  const obj = {
                        id: sessionStorage.getItem("_MJC_SESSION"),
                        first_name: values.first_name,
                        last_name: values.last_name,
                        address_1: values?.billingaddress1 ? values?.billingaddress1 : values.address1,
                        city: values.values?.billingtown ? values?.billingtown : values.town,
                        postcode: values?.billingpostcode ? values?.billingpostcode : values.postcode,
                        amount: data[ 0 ].price_gross
                  }

                  const payment = await registerPayment(obj)

                  payment.status === "OK" ? window.location.href = payment.action : message.error("Sorry, there has been a problem with our payment gateway. Please call the office.")

            } else {
                  message.error(result.result)
                  setButtonText(`Pay £${price}`)
                  setDisableButton(false)
            }

      }

      const onFinishFailed = (errorInfo) => {
            message.error("Your order form contains errors.")
      }

      const orderSummary = <>
            <div className="summary-card">
                  <Row>
                        <Col span={22}><h2><HiOutlineCube className="orderh2icon" /> Order summary</h2></Col>
                        <Col span={2}><TiArrowBackOutline className="clickable" style={{ fontSize: '2rem', opacity: 0.3 }} onClick={() => navigate('/')} /></Col>
                  </Row>

                  <Divider />
                  <h3>TYPE: The {data[ 0 ].skip_size} Yard</h3>
                  <h3>WASTE TYPE: {data[ 0 ].inert_material < 1 ? 'General Waste' : 'Soil & Rubble'}</h3>
                  <h3>ROAD LICENCE: {data[ 0 ].road_licence < 1 ? 'Not required' : 'Required & included in price'}</h3>
                  <Divider />
                  <h3>NET PRICE: £{data[ 0 ].price_net}</h3>
                  <h3>VAT: £{data[ 0 ].price_vat_nominal}</h3>
                  <h3>TOTAL PRICE: £{data[ 0 ].price_gross}</h3>
                  <Divider />
                  <h3>DELIVERY: {`${data[ 0 ].delivery_address_1}, ${data[ 0 ]?.delivery_address_2 && data[ 0 ]?.delivery_address_2.length > 0 ? `${data[ 0 ].delivery_address_2}, ` : ``}${data[ 0 ].delivery_address_town} ,${data[ 0 ].delivery_address_postcode}`}</h3>
                  <h3>PERIOD: Price includes 14 days hire. Should you require longer, call us after placing your order.</h3>
                  <Divider />
                  <Alert message="Need to change something?" description={<p style={{ margin: 0 }}>Just re-run your quote <span style={{ cursor: "pointer", color: "#1890ff", fontWeight: 500 }} onClick={() => navigate('/')} > here</span>. It'll only take a few seconds.</p>} type="warning" />

            </div>
      </>

      // const disabledDate = (current) => {
      //       return current && (current < moment().add(1, 'd') || moment(current).day() === 0 || moment(current).day() === 6 || bankHolidays.includes(moment(current).format("YYYY-MM-DD")))
      // }

      const disabledDate = (current) => {

            // Keep the original condition as you specified
            if (current && (current < moment().add(1, 'd') || current.day() === 0 || current.day() === 6 || bankHolidays.includes(current.format("YYYY-MM-DD")))) {
                  return true
            }

            // Calculate the 25th of December of the current year
            const dec25 = moment([ current.year(), 11, 25 ])

            // Check if the current date is after 25th December and disable it
            if (current.isSameOrAfter(dec25) && current.month() === 11) {
                  return true
            }

            // If the year of the current date is not the same as dec25, then we are in January
            if (current.year() !== dec25.year()) {
                  let janDate = moment([ current.year(), 0, 1 ]) // January 1st of the current year (which is next year relative to dec25)
                  let workingDaysPassed = 0

                  // Loop to find the 3rd working day of January
                  while (workingDaysPassed < 3) {
                        if (janDate.day() !== 0 && janDate.day() !== 6 && !bankHolidays.includes(janDate.format("YYYY-MM-DD"))) {
                              workingDaysPassed++
                        }
                        // Increase the date only if we haven't reached the 3rd working day
                        if (workingDaysPassed < 3) {
                              janDate.add(1, 'days')
                        }
                  }

                  // If current date is before the 3rd working day of January, disable it
                  if (current.isSameOrBefore(janDate, 'day').add(1, 'day')) {
                        return true
                  }
            }

            // Enable the current date since it's not falling under the disabled conditions
            return false
      }




      const billingAddress =
            <div id="billing-address-wrapper">
                  <Form.Item
                        label="Billing Address 1"
                        name="billingaddress1"
                  >
                        <Input value={data[ 0 ].delivery_address_1} placeholder="eg Enter your address #1" />
                  </Form.Item>

                  <Form.Item
                        label="Address 2"
                        name="billingaddress2"
                  >
                        <Input placeholder="optional" />
                  </Form.Item>

                  <Form.Item
                        label="Billing Town or City"
                        name="billingtown"
                  >
                        <Input placeholder="eg Bath" />
                  </Form.Item>

                  <Form.Item
                        label="Billing County"
                        name="billingcounty"
                  >
                        <Input placeholder="eg BANES" />
                  </Form.Item>

                  <Form.Item
                        label="Billing Postcode"
                        name="billingpostcode"
                  >
                        <Input placeholder="eg BA1 5RS" />
                  </Form.Item>

            </div>

      return (
            <>
                  <Row justify="center" style={{ margin: "100px 0" }}>

                        <Col xs={22} sm={22} md={14} >

                              <Form
                                    name="basic"
                                    layout="vertical"
                                    wrapperCol={{ span: 18 }}
                                    initialValues={{
                                          remember: true,
                                          first_name: data?.customer_first_name !== null ? data.customer_first_name : '',
                                          address1: data?.delivery_address_1 !== null ? data.delivery_address_1 : ''
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    form={form}
                                    scrollToFirstError={{ behavior: 'smooth' }}

                              >

                                    {isLoading ? <Loading style={{ marginBottom: "100px" }} /> : orderSummary}


                                    <div className="summary-card">

                                          <h2><GrDeliver className="orderh2icon" /> Delivery information</h2>
                                          <Divider />

                                          <Form.Item label="When do you want the skip?" name="date" rules={[ { required: true, message: 'Please input a delivery date' } ]}>
                                                <DatePicker disabledDate={disabledDate} />
                                          </Form.Item>

                                          <Form.Item
                                                label="Address 1"
                                                name="address1"
                                                style={{ display: 'none' }}
                                                rules={[ { required: true, message: 'Please enter your address' } ]}
                                          >
                                                <Input value={data[ 0 ].delivery_address_1} placeholder="eg Enter your address #1" />
                                          </Form.Item>

                                          <Form.Item
                                                label="Address 2"
                                                name="address2"
                                                style={{ display: 'none' }}
                                          >
                                                <Input placeholder="optional" />
                                          </Form.Item>

                                          <Form.Item
                                                label="Town or City"
                                                name="town"
                                                style={{ display: 'none' }}
                                                rules={[ { required: true, message: 'Please enter your town or city' } ]}
                                          >
                                                <Input placeholder="eg Bath" />
                                          </Form.Item>

                                          <Form.Item
                                                label="County"
                                                name="county"
                                                style={{ display: 'none' }}
                                                rules={[ { required: true, message: 'Please enter your county' } ]}
                                          >
                                                <Input placeholder="eg BANES" />
                                          </Form.Item>

                                          <Form.Item
                                                label="Postcode"
                                                name="postcode"
                                                style={{ display: 'none' }}
                                                rules={[ { required: true, message: 'Please enter your postcode' } ]}
                                          >
                                                <Input placeholder="eg BA1 5RS" />
                                          </Form.Item>


                                          <Divider />

                                          <Form.Item
                                                label="Order notes"
                                                name="order_notes">
                                                <TextArea rows={4} placeholder="Optional ; let us know of any important information" maxLength={500} />
                                          </Form.Item>

                                    </div>




                                    <div className="summary-card">

                                          <h2><MdFace className="orderh2icon" /> Your information</h2>
                                          <Divider />

                                          <Form.Item
                                                style={{
                                                      marginBottom: 0,
                                                }}
                                          >
                                                <Form.Item
                                                      label="First name"
                                                      name="first_name"
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  message: 'Please enter your first name'
                                                            },
                                                      ]}
                                                      style={{
                                                            display: 'inline-block',
                                                            width: 'calc(50% - 8px)',
                                                      }}
                                                >
                                                      <Input placeholder="eg John" />
                                                </Form.Item>
                                                <Form.Item
                                                      label="Last name"
                                                      name="last_name"
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  message: 'Please enter your last name'
                                                            },
                                                      ]}
                                                      style={{
                                                            display: 'inline-block',
                                                            width: 'calc(50% - 8px)',
                                                            margin: '0 8px',
                                                      }}
                                                >
                                                      <Input placeholder="eg Smith" />
                                                </Form.Item>
                                          </Form.Item>

                                          <Form.Item
                                                style={{
                                                      marginBottom: 0,
                                                }}
                                          >
                                                <Form.Item
                                                      label="Telephone"
                                                      name="telephone"
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  message: 'Please enter your phone number'
                                                            },
                                                            {
                                                                  pattern: /^\d+$/,
                                                                  message: 'Numbers only please!',
                                                            }
                                                      ]}
                                                      style={{
                                                            display: 'inline-block',
                                                            width: 'calc(50% - 8px)',
                                                      }}
                                                >
                                                      <Input placeholder="eg 07917 670 402" />
                                                </Form.Item>
                                                <Form.Item
                                                      label="Email"
                                                      name="email"
                                                      rules={[
                                                            {
                                                                  required: true,
                                                                  message: 'Please enter your email'
                                                            },
                                                            {
                                                                  type: "email",
                                                                  message: "The email you've entered isn't a valid format",
                                                            }
                                                      ]}
                                                      style={{
                                                            display: 'inline-block',
                                                            width: 'calc(50% - 8px)',
                                                            margin: '0 8px',
                                                      }}
                                                >
                                                      <Input placeholder="eg john.smith@gmail.com" />
                                                </Form.Item>
                                                <Form.Item
                                                      label="Company name"
                                                      name="company_name"
                                                      style={{
                                                            display: 'inline-block',
                                                            width: 'calc(100% - 8px)',
                                                      }}
                                                >
                                                      <Input placeholder="Optional" />
                                                </Form.Item>
                                          </Form.Item>

                                    </div>

                                    <div className="summary-card">

                                          <h2><MdOutlinePayment className="orderh2icon" /> Payment</h2>
                                          <Divider />

                                          <Checkbox defaultChecked="true" onChange={(e) => setIsBilling(e.target.checked)}>Use delivery address as billing address</Checkbox>

                                          {isBilling ? null : billingAddress}

                                          <Form.Item
                                                name="tandc"
                                                valuePropName="checked"
                                                rules={[
                                                      {
                                                            validator: async (_, checked) => {
                                                                  if (!checked) {
                                                                        return Promise.reject(
                                                                              new Error("Please review and accept our skip hire terms and conditions."),
                                                                        )
                                                                  }
                                                            },
                                                      },

                                                ]}
                                          >
                                                <Checkbox style={{ marginTop: "25px" }} defaultChecked={tc} onChange={(e) => setTC(e.target.checked)}>You agree to our skip hire <a href="/terms" target="_blank">terms & conditions</a>.</Checkbox>
                                          </Form.Item>

                                          <Form.Item style={{ marginTop: "50px" }}>
                                                <Button type="primary" htmlType="submit" size="large" disabled={disableButton}>{buttonText}</Button>
                                          </Form.Item>


                                    </div>

                              </Form>

                        </Col>

                  </Row>

            </>
      )
}