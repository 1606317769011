import { v4 as uuid } from "uuid"
import { config } from "./config"
import axios from "axios"
import moment from "moment"

// Establish environment
const env = window.location.host.includes("www") ? "prod" : "stage"
const c = config[ env ]

export function round(value, decimals) {
      return Number(Math.round(value * 100) / 100).toFixed(decimals)
}

export async function getHolidays() {
      let data = []
      let bhs = await axios.get('https://www.gov.uk/bank-holidays.json')
      for (let d of bhs.data[ 'england-and-wales' ].events) {
            if (moment(d.date).isAfter()) { data.push(d.date) }
      }

      return data
}

export async function getQuote(data, address) {
      console.log('xmas request:', JSON.stringify(data))
      let result = await axios.post(c.api_base + '/orders/quote', { ...data, ...address })
      // console.log('rfq response:', JSON.stringify(result.data))
      return result.data
}

export async function getOrder(id, options = {}) {
      // console.log('order request:', id)
      if (options.ga4) { console.log('ga4'); options = { ...options, ga4: true } }
      let result = await axios.post(c.api_base + '/orders/get', { id, options })
      // console.log('order response:', JSON.stringify(result.data))
      return result.data
}

export async function saveOrder(path, data) {
      let result = await axios.post(c.api_base + path, data)
      // console.log(JSON.stringify(result.data))
      return result.data
}

export async function registerPayment(obj) {
      const { data } = await axios.post(c.api_base + '/payment/register', obj)
      // console.log(JSON.stringify(data))
      return data

}

export function sessionHandler() {

      const path = window.location.pathname

      if (!path.includes("/order") && !path.includes("/complete")) {

            // console.log('sessionHandler()')

            let uid, sid

            if (localStorage.getItem("_MJC_USER") === null) {
                  // Create new user in local storage
                  uid = uuid()
                  localStorage.setItem("_MJC_USER", uid)
            } else {
                  uid = localStorage.getItem("_MJC_USER")
            }

            if (sessionStorage.getItem("_MJC_SESSION") === null) {
                  // Create new session in session storage
                  sid = uuid()
                  sessionStorage.setItem("_MJC_SESSION", sid)
                  saveOrder('/orders/create', { id: sid, user_id: uid, ua: navigator.userAgent, landing_url: window.location.href })
            }
      }

      return

}

