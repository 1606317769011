export const config = {
      prod: {
            api_base: 'https://api.mjchurchskiphire.co.uk',
            google_maps_api_key: 'AIzaSyDCpEPHpzfBcA2TfIvfkHjd3OlPl9MOPMY',
            ideal_postcodes_api_key: "ak_lajw6j8svbPznY4BOlqi6MFmroP9H",
            ga4_measurement_id: 'G-GB7XDP3KYG',
            telephone: <span class='nimbata_number'>01249 4793500</span>
      },
      stage: {
            api_base: 'https://stage-api.mjchurchskiphire.co.uk',
            google_maps_api_key: 'AIzaSyAlASd9e7YbN5vaGVUucufwfSGF2wEsBDg',
            ideal_postcodes_api_key: "ak_lajw6j8svbPznY4BOlqi6MFmroP9H",
            ga4_measurement_id: 'G-DWD15VFQD1',
            telephone: <span class='nimbata_number'>01249 4793500</span>
      }
}