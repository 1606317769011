import { Row, Col } from 'antd'
import { Helmet } from "react-helmet"

export function Terms() {

      return (

            <>

                  <Helmet>
                        <meta charSet="utf-8" />
                        <title>Terms & Conditions | MJ Church Plant Limited</title>
                        <link rel="canonical" href="https://www.mjchurchskiphire.co.uk/terms" />
                        <meta name="description" content="MJ Church are the No 1 Local Skip Hire company. For over 30 years we've been servicing our loyal local customers with an excellent 'One-Stop Shop' skip hire service." />
                  </Helmet>

                  <div id="static-content-wrapper">

                        <Row justify="center">

                              <Col xs={22} sm={22} md={22} lg={20} xxl={18}>

                                    <h1>Terms & Conditions - Skip Hire</h1>

                                    <ol>
                                          <li>
                                                <h2>Meaning</h2>
                                                <ol>
                                                      <li>
                                                            <h3>In this Contract the following phrases have the following meanings:</h3>
                                                      </li>
                                                </ol>
                                          </li>
                                    </ol>
                                    <div style={{ marginLeft: "75px" }}>
                                          <p>&ldquo;Hirer&rdquo;&nbsp; &nbsp;&nbsp;the person, firm, company or organisation specified on the Quotation;</p>
                                          <p>&ldquo;Commencement Date&rdquo;&nbsp; &nbsp;&nbsp;means the date the Order is confirmed by the Company;&nbsp;</p>
                                          <p>&ldquo;Company&rdquo;&nbsp; &nbsp;&nbsp;M.J.Church (Plant) Limited, registered in England and Wales with company number 01856955;</p>
                                          <p>&ldquo;Data Protection Legislation&rdquo;&nbsp; &nbsp;&nbsp;the Data Protection Act 2018;&nbsp;</p>
                                          <p>&ldquo;Delivery&rdquo;&nbsp; &nbsp;&nbsp;delivery of the Equipment to the location agreed in the Order;&nbsp;</p>
                                          <p>&nbsp;&ldquo;Equipment&rdquo;&nbsp; &nbsp;&nbsp;the skip (s) or vehicle (s) set out in the Order, substitutions, replacements or renewals of such skips(s) or vehicle(s) and all related accessories, manuals and instructions for them;</p>
                                          <p>&ldquo;Hire Period&rdquo;&nbsp; &nbsp;&nbsp;the period of hire of the Equipment set out in the Order;</p>
                                          <p>&ldquo;Order&rdquo;&nbsp; &nbsp;&nbsp;means the Hirer&rsquo;s order for the Equipment via via the internet or phone&nbsp;.</p>
                                          <p>&ldquo;Price&rdquo;&nbsp; &nbsp;&nbsp;means the price payable for the Equipment as detailed in the Quotation;</p>
                                          <p>&ldquo;Quotation&rdquo;&nbsp; &nbsp;&nbsp;means the Company&rsquo;s written quotation for the hire of the Equipment;</p>
                                          <p>&ldquo;Site&rdquo;&nbsp; &nbsp;&nbsp;the premises to which the Equipment will be delivered in as detailed in the Order;&nbsp;</p>
                                          <p>&ldquo;Working Day&rdquo;&nbsp; &nbsp;&nbsp;a day other than a Saturday or Sunday or other public or statutory holiday in England and Wales.</p>
                                    </div>

                                    <ol start="2">
                                          <li>
                                                <h2>Basis of the Contract and Term</h2>
                                                <ol>
                                                      <li>
                                                            <p>This Contract together with the Quotation and Order constitute the whole agreement between the parties and supersede any prior promises, representations, undertakings or implications whether written or oral. No variation shall &nbsp;take effect unless agreed in writing by the parties.</p>
                                                      </li>
                                                </ol>

                                                <ol start="2">
                                                      <li>
                                                            <p>Any Quotation shall only be valid for 20 Working Days from its date. Prices quoted are based on information available at the date of Quotation. The Company reserves the right to vary the Price at any time without notice prior to acceptance of an Order.&nbsp;</p>
                                                      </li>
                                                </ol>

                                                <ol start="3">
                                                      <li>
                                                            <p>The Contract takes effect from the date the Company confirms the Order. Confirmation of Order is provided by email or internet booking confirmation.&nbsp;The Hirer is responsible for ensuring that the Order is complete and accurate.</p>
                                                      </li>
                                                </ol>

                                                <ol start="4">
                                                      <li>
                                                            <p>Where the hire of the Equipment is to a consumer and the hire would be covered by the Consumer Credit Act 1974 (as amended) the duration of the hire shall not exceed 3 months.</p>
                                                      </li>
                                                </ol>

                                                <ol start="5">
                                                      <li>
                                                            <p>The maximum Hire Period is 14 days for cash customers and 30 days for account customers. It is the responsibility of the Hirer to request collection of the Equipment. Failure to do so will result in unannounced collection and /or additional rental charge being incurred of &pound;50 per week.&nbsp;</p>
                                                      </li>
                                                </ol>

                                                <ol start="6">
                                                      <li>
                                                            <p>The Company reserves the right to make any changes in the specification of the Equipment which are required to confirm with any applicable safety or other statutory requirements. All descriptions and specifications contained on the Company&rsquo;s website or marketing materials are to give an approximate idea and do not form part of the Contract.&nbsp;</p>
                                                      </li>
                                                </ol>
                                          </li>
                                    </ol>



                                    <ol start="3">
                                          <li>
                                                <h2>Terms of Use</h2>
                                                <ol>
                                                      <li>
                                                            <p>The Hirer shall</p>
                                                            <ol>
                                                                  <li>
                                                                        <p>provide and maintain all access to the Site for the delivery and collection of the Equipment and ensure that if the Equipment is placed on the public highway it is properly coned during the hours of daylight and lit during the hours of darkness;</p>
                                                                  </li>
                                                            </ol>

                                                            <ol start="2">
                                                                  <li>
                                                                        <p>take reasonable care of the Equipment including but not limited to taking adequate and proper measures to protect it from theft and damage, ensure that the Equipment is not overloaded by weight or volume at the point of collection and is filled no higher than the top of its sides. The Company may without liability to the Hirer, refuse to collect the Equipment if it is overloaded and charge a wasted journey fee (see clause 4.3);</p>
                                                                  </li>
                                                            </ol>

                                                            <ol start="3">
                                                                  <li>
                                                                        <p>be responsible for ensuring the suitability for all access to the siting of the Equipment, taking into account ground conditions, the weight of the Equipment and of the vehicle delivering it. This includes ensuring that the Site can withstand the weight of both the Equipment and the vehicle;</p>
                                                                  </li>
                                                            </ol>

                                                            <ol start="4">
                                                                  <li>
                                                                        <p>not place any prohibited waste in the Equipment unless by prior written agreement with the Company. This includes but is not limited to animal carcasses, asbestos, batteries, computer monitors, tyres, electrical items, TV&rsquo;s, printers, microwaves, fluorescent light tubes, food waste, oils, chemicals, corrosives, toxic or danger materials, paint tins with residue, gas cylinders, plasterboard, refrigerators &amp; freezers, mattresses, surgical waste or anything else notified as prohibited by from time to time by the Company;</p>
                                                                  </li>
                                                            </ol>

                                                            <ol start="5">
                                                                  <li>
                                                                        <p>not use the Equipment for any unlawful purpose;</p>
                                                                  </li>
                                                            </ol>

                                                            <ol start="6">
                                                                  <li>
                                                                        <p>where the Equipment is to be placed on a location other than on private property, obtain the permission of the Highway Authority as may be required under s.139 Highways Act 1980 and maintain it for the Hire Period. The Company can organise this permit at an additional cost;</p>
                                                                  </li>
                                                            </ol>

                                                            <ol start="7">
                                                                  <li>
                                                                        <p>not light any fires in or near the Equipment;</p>
                                                                  </li>
                                                            </ol>

                                                            <ol start="8">
                                                                  <li>
                                                                        <p>not move or reposition the Equipment without the prior written consent of the Company or part with control of the Equipment or allow the Equipment to be confiscated or seized. Any Equipment that is removed whilst in the control of the Hirer will be charged at the full replacement value;</p>
                                                                  </li>
                                                            </ol>

                                                            <ol start="9">
                                                                  <li>
                                                                        <p>comply with all safety and use instructions issued by the Company;</p>
                                                                  </li>
                                                            </ol>

                                                            <ol start="10">
                                                                  <li>
                                                                        <p>not alter the Equipment or remove any component of it;</p>
                                                                  </li>
                                                            </ol>

                                                            <ol start="11">
                                                                  <li>
                                                                        <p>permit the Company to inspect the Equipment at any time during the Hire Period;</p>
                                                                  </li>
                                                            </ol>


                                                            <ol start="12">
                                                                  <li>
                                                                        <p>notify the Company immediately if any of the provisions of this clause 3 are breached and cease use of the Equipment until otherwise directed by the Company.</p>
                                                                  </li>
                                                            </ol>
                                                      </li>
                                                </ol>
                                          </li>
                                    </ol>




                                    <ol start="4">
                                          <li>
                                                <h2>Delivery&nbsp;</h2>
                                                <ol>
                                                      <li>
                                                            <p>The Company undertakes to use its reasonable endeavours to deliver the Equipment on the date specified in the Quotation. &nbsp;All dates are approximate only and time is not of the essence in this regard. The Company shall not be liable to the Hirer if it is prevented or delayed from performing any of its obligations under this Contract by reason of any act or omission of the Hirer.&nbsp;</p>
                                                      </li>
                                                      <li>
                                                            <p>The Equipment shall be delivered to the Site. The time allowed for loading/unloading is 15 minutes. If the delivery vehicle is kept waiting longer the Company reserves the right to charge a waiting time of &pound;75 per hour . The Hirer must ensure that an authorised representative of the Hirer is present at the Site to take delivery of the Equipment. Acceptance of delivery by the authorised representative shall be evidence that the Hirer has examined the Equipment and confirmed it condition for the Hire Period.&nbsp;</p>
                                                      </li>
                                                      <li>
                                                            <p>The Company reserves the right to charge a wasted journey fee of &pound;100 if the Company is unable to deliver or collect the Equipment for reasons not attributable to the Company.</p>
                                                      </li>
                                                      <li>
                                                            <p>Risk in the Equipment will pass to the Hirer on delivery. Title to the Equipment remains with the Company at all times.&nbsp;</p>
                                                      </li>
                                                      <li>
                                                            <p>Any liability of the Company for non-delivery of the Equipment shall be limited to the direct cost incurred by the Hirer in obtaining replacement Equipment in the cheapest market available less the price of the hire of the Equipment.</p>
                                                      </li>
                                                </ol>
                                          </li>
                                    </ol>

                                    <ol start="5">

                                          <li>
                                                <h2>Price and Payment</h2>
                                                <ol>
                                                      <li>
                                                            <p>The Price shall be payable in accordance with the rates, payment dates and in the currency specified in the Quotation. &nbsp;All Prices are exclusive of VAT where applicable.</p>
                                                      </li>
                                                      <li>
                                                            <p>If the Hirer is paying by cash then payment is required on or before delivery otherwise the Equipment will not be delivered. Payment for Hirers with accounts must be made within 30 days from the date of invoice. If an account holder exceeds their agreed limit, payment of any amount in exceed of the agreed limited will become due and payable immediately to settle the balance.[.</p>
                                                      </li>
                                                      <li>
                                                            <p>No payments shall be deemed to have been received by the Company until it has received cleared funds. &nbsp;</p>
                                                      </li>
                                                      <li>
                                                            <p>All payments payable to the Company under this Contract shall become due immediately on termination of these Contract despite any other provision. &nbsp;</p>
                                                      </li>
                                                      <li>
                                                            <p>The Hirer shall make all payments due under this Contract without any deduction whether by way of set-off, counterclaim, discount, abatement or otherwise.&nbsp;</p>
                                                      </li>
                                                      <li>
                                                            <p>If the Hirer fails to pay any sum due under this Contract on the due date, the Company shall be entitled, without limiting any other rights it may have to charge interest on the outstanding amount at the rate of 4% (if the Hirer is a consumer) or otherwise the rate under the Late Payment of Commercial Debts (Interest) Act.</p>
                                                      </li>
                                                </ol>
                                          </li>
                                    </ol>

                                    <ol start="6">
                                          <li>
                                                <h2>Cancellation of Orders</h2>
                                                <ol>
                                                      <li>
                                                            <p>If the Hirer is a consumer, the Hirer may cancel the Order in accordance with the Consumer Contracts (Information, Cancellation and Additional Charges) Regulations 2013 within 14 days of the Order provided that the Equipment has not been provided within those 14 days. Cancellation may be communicated by email, phone or in writing. A refund will be provided within 30 days.</p>
                                                      </li>
                                                      <li>
                                                            <p>Following the Commencement Date, no cancellation or suspension of the Order by the Hirer &nbsp;shall be valid or binding unless notified in writing to the Company and agreed in writing by the Company.</p>
                                                      </li>
                                                </ol>
                                          </li>
                                          <li>
                                                <h2>Liability</h2>
                                                <ol>
                                                      <li>
                                                            <p>The restrictions on liability in this clause 7 apply to every liability arising under or in connection with this Contract including liability in contract, tort (including negligence), misrepresentation, restitution or otherwise.</p>
                                                      </li>
                                                      <li>
                                                            <p>Nothing in this agreement limits any liability which cannot legally be limited including liability for</p>
                                                            <ol>
                                                                  <li>
                                                                        <p>death or personal injury caused by negligence;&nbsp;</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>fraud or fraudulent misrepresentation;</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>breach of the terms implied by section 7 of the Supply of Goods and Services Act;&nbsp;</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>any matter in respect of which it would be unlawful for the parties to exclude or restrict liability.</p>
                                                                  </li>
                                                            </ol>
                                                      </li>
                                                      <li>
                                                            <p>Subject to clause 7.2, the Company&rsquo;s total liability to the Hirer shall not exceed the Price.</p>
                                                      </li>
                                                      <li>
                                                            <p>Subject to clause 7.2, the Company shall not be liable under this Contract for any:</p>
                                                            <ol>
                                                                  <li>
                                                                        <p>losses or damage arising from breach of this Contract by the Hirer;</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>loss of profits;</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>loss of sales or business;</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>loss of agreements or contracts;</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>loss of anticipated savings;</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>loss of use or corruption of software, data or information;</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>loss of or damage to goodwill; and</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>indirect or consequential loss.</p>
                                                                  </li>
                                                            </ol>
                                                      </li>
                                                      <li>
                                                            <p>Subject to clause 7.2, all implied terms and conditions as to the quality or performance of the Equipment and any other goods or services provided under this agreement are, to the fullest extent permitted by law, excluded from this agreement.&nbsp;</p>
                                                      </li>
                                                      <li>
                                                            <p>The Hirer shall indemnify the Company in full against all liabilities, costs, expenses, damages and losses (including any direct, indirect or consequential losses, loss of profit, loss of reputation and all interest, penalties and legal costs (calculated on a full indemnity basis) and all other reasonable professional costs and expenses) suffered or incurred by the Company arising out of, or in connection with any failure by the Hirer to comply with the terms of this Contract.</p>
                                                      </li>
                                                </ol>
                                          </li>
                                    </ol>

                                    <ol start="8">
                                          <li>
                                                <h2>Data Protection</h2>
                                                <ol>
                                                      <li>
                                                            <p>The Company agrees to comply with all applicable requirements of Data Protection Legislation.&nbsp;</p>
                                                      </li>
                                                      <li>
                                                            <p>The privacy notice contained on the Company&rsquo;s website sets out the type, scope, nature, duration, and purpose of personal data processed by the Company under this Contract. The Hirer agrees to ensure that it has all necessary appropriate consents and notices in place to enable lawful transfer of the personal data to the Company for the duration and purpose of this Contract.</p>
                                                      </li>
                                                </ol>
                                          </li>
                                    </ol>

                                    <ol start="9">
                                          <li>
                                                <h2>Termination</h2>
                                                <ol>
                                                      <li>
                                                            <p>Without affecting any other right or remedy available to it, the Company may terminate this Contract with immediate effect by giving written notice to the Hirer if the Hirer:</p>
                                                            <ol>
                                                                  <li>
                                                                        <p>commits any breach of any of this Contract; or</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>has an encumbrancer take possession of or a receiver or administrator or administrative receiver appointed over any of its property or assets; or</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>makes a voluntary arrangement with its creditors or becomes subject to an administration order or bankruptcy order or is unable to pay its debts; or</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>enters into liquidation (except for the purposes of amalgamation or reconstruction and in such manner that the company resulting from it effectively agrees to be bound by or assume the obligations of the Hirer under this Contract; or</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>ceases or threaten to cease to carry on business.</p>
                                                                  </li>
                                                            </ol>
                                                      </li>
                                                      <li>
                                                            <p>On expiry or termination of this Contract, however caused:</p>
                                                            <ol>
                                                                  <li>
                                                                        <p>the Company&rsquo;s consent to the Hirer&rsquo;s possession of the Equipment shall terminate;</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>the Company may, by its authorised representatives, without notice and at the Hirer&rsquo;s expense, retake possession of the Equipment and for this purpose may enter the Site or any premises at which the Equipment is located;</p>
                                                                  </li>
                                                                  <li>
                                                                        <p>the Hirer shall immediately pay to the Company all of the Company&rsquo;s outstanding unpaid invoices and interest and in respect of the Equipment. For charges for which no invoice has been submitted, the Company may submit an invoice, which shall be payable in accordance with clause 5.2.</p>
                                                                  </li>
                                                            </ol>
                                                      </li>
                                                      <li>
                                                            <p>Any provision of this Contract that expressly or by implication is intended to come into or continue in force on or after termination or expiry of this Contract shall remain in full force and effect.</p>
                                                      </li>
                                                      <li>
                                                            <p>Termination or expiry of this Contract shall not affect any rights, remedies, obligations or liabilities of the parties that have accrued up to the date of termination or expiry, including the right to claim damages in respect of any breach of the Contract which existed at or before the date of termination or expiry.</p>
                                                      </li>
                                                </ol>
                                          </li>
                                    </ol>

                                    <ol start="2">

                                    </ol>

                                    <ol start="3">

                                    </ol>
                                    <ol start="10">
                                          <li>
                                                <h2>Force Majeure</h2>
                                                <ol>
                                                      <li>
                                                            <p>The Company reserves the right to defer delivery or to cancel the Order without liability to the Hirer if it or its suppliers are prevented from or delayed in the carrying on of business or fulfilling its obligations to the Hirer due to circumstances outside its or their reasonable control including but, without limitation, strike, lock-out or other industrial action (whether or not relating to either party&rsquo;s workforce), terrorist activity, civil commotion, government action, acts of God (including pandemic), war or national emergency or other circumstances beyond their reasonable control.&nbsp;</p>
                                                      </li>
                                                </ol>
                                          </li>
                                    </ol>

                                    <ol start="11">
                                          <li>
                                                <h2>Miscellaneous</h2>
                                                <ol>
                                                      <li>
                                                            <p>Failure or delay by the Company in enforcing or partially enforcing any provision of this Contract shall not be construed as a waiver of any of its rights under this Contract. No waiver of any provision of this Contract by either party shall be deemed to be a further or continuing waiver of any subsequent breach of that term or condition or any other term or condition.</p>
                                                      </li>
                                                      <li>
                                                            <p>The Hirer shall not be entitled to assign, sub-licence, sub-contract, novate or transfer any of its rights or liabilities under these Contract without the prior written consent of the Company.&nbsp;</p>
                                                      </li>
                                                      <li>
                                                            <p>A person who is not party to this Contract has no right under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this Contract.&nbsp;</p>
                                                      </li>
                                                      <li>
                                                            <p>This Contract and any disputes or claims arising out of or in connection with them are governed by and construed in accordance with the law of England. The parties irrevocably agree that the courts of England have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with this Contract.</p>
                                                      </li>
                                                      <li>
                                                            <p>Any notices required under this Contract shall be in writing and delivered in person, sent by registered mail or email to the respective parties address as set out in the Order confirmation or as otherwise notified to the other party. &nbsp;Such notice shall be considered to have been given on the first working day of actual delivery or sending by email or in any event within 2 working days after it was posted in the manner set out above.&nbsp;</p>
                                                      </li>
                                                </ol>
                                          </li>
                                    </ol>
                              </Col>
                        </Row>

                  </div>

            </>
      )
}