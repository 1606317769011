import { Row, Col, Button, Divider } from 'antd'
import { Helmet } from "react-helmet"
import SkipHire from "../../../images/services/skip-hire.jpg"
import WasteManagement from "../../../images/services/waste-management.jpg"
import Recycling from "../../../images/services/recycling-services.jpg"
import ScrapMetal from "../../../images/services/scrap-metal.jpg"
import { useNavigate } from "react-router-dom"

export function Services() {

      const navigate = useNavigate()

      return (

            <>

                  <Helmet>
                        <meta charSet="utf-8" />
                        <title>Our Services | Skip hire, Waste Management, Recyling & Scrap Metal | MJ Church Plant Limited</title>
                        <link rel="canonical" href="https://www.mjchurchskiphire.co.uk/services" />
                        <meta name="description" content="MJ Church are the No 1 Local Skip Hire company. For over 30 years we've been servicing our loyal local customers with an excellent 'One-Stop Shop' skip hire service." />
                  </Helmet>

                  <div id="static-content-wrapper">

                        <Row justify="center">

                              <Col xs={22} sm={22} md={22} lg={20} xxl={18}>

                                    <h1>Our Services.</h1>
                                    <p style={{ fontSize: "120%" }}>During our four decades of operation, we have seen a considerable shift towards recycling and people choosing to lead a more sustainable lifestyle or business. Paying attention to this, we have adapted our services accordingly which has allowed us to expand and prosper.</p>
                                    <Divider style={{ marginBottom: "75px" }} />

                                    <Row gutter={25}>

                                          <Col className="gutter-row service-card" xs={24} lg={12}>
                                                <img src={SkipHire} alt="Skip Hire" />
                                                <div className="service-title">
                                                      <h2>SKIP HIRE</h2>
                                                      <Divider style={{ margin: "15px 0", borderTop: "1px solid rgba(255,255,255,0.08)" }} />
                                                      <p style={{ marginBottom: "35px" }}>Our competitive prices, fast response and accredited services have made us a leading skip hire company for both domestic and commercial customers throughout Chippenham, Swindon and Bristol. </p>
                                                      <Button type="primary" htmlType="submit" onClick={() => navigate("/")}>Get a quote today</Button>
                                                </div>
                                          </Col>

                                          <Col className="gutter-row service-card" xs={24} lg={12}>
                                                <img src={WasteManagement} alt="Skip Hire" />
                                                <div className="service-title">
                                                      <h2>WASTE MANAGEMENT</h2>
                                                      <Divider style={{ margin: "15px 0", borderTop: "1px solid rgba(255,255,255,0.08)" }} />
                                                      <p style={{ marginBottom: "35px" }}>Responsible recycling has become intrinsic to how our company operates and we achieve recycling rates of over 95%, with much of our waste being sent to ‘waste to energy’ facilities across the South West.</p>
                                                      <Button type="primary" htmlType="submit" onClick={() => navigate("/contact")}>Contact us to find out more</Button>
                                                </div>
                                          </Col>

                                          <Col className="gutter-row service-card" xs={24} lg={12}>
                                                <img src={Recycling} alt="Skip Hire" />
                                                <div className="service-title">
                                                      <h2>RECYCLING SERVICES</h2>
                                                      <Divider style={{ margin: "15px 0", borderTop: "1px solid rgba(255,255,255,0.08)" }} />
                                                      <p style={{ marginBottom: "35px" }}>We operate dedicated sites where all collected waste is taken to be sorted and processed. In doing so, we are able to reduce the amount of recyclable waste being sent to landfill, instead it is separated and transported to the most appropriate facility to be recycled or reused. </p>
                                                      <Button type="primary" htmlType="submit" onClick={() => navigate("/contact")}>Contact us to find out more</Button>
                                                </div>
                                          </Col>

                                          <Col className="gutter-row service-card" xs={24} lg={12}>
                                                <img src={ScrapMetal} alt="Skip Hire" />
                                                <div className="service-title">
                                                      <h2>SCRAP METAL</h2>
                                                      <Divider style={{ margin: "15px 0", borderTop: "1px solid rgba(255,255,255,0.08)" }} />
                                                      <p style={{ marginBottom: "35px" }}>By choosing to sell to us, you will receive a rebate in line with market prices at the time of the transaction based on the value we can recover. This amount is normally paid within 3-4 working days so you can make a quick profit on the materials you no longer need. </p>
                                                      <Button type="primary" htmlType="submit" onClick={() => navigate("/contact")}>Contact us to find out more</Button>
                                                </div>
                                          </Col>
                                    </Row>

                              </Col>

                        </Row>

                  </div>

            </>
      )
}