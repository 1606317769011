import { Row, Col } from 'antd'
import { useNavigate } from "react-router-dom"
import Logo from '../../images/logo.png'
import OrderSkip from '../../images/order-skip.png'
import moment from "moment"
import { MyContext } from "../../App"
import { useContext } from "react"

export function Footer() {

      const navigate = useNavigate()
      let { AppData } = useContext(MyContext)

      if (AppData?.hideFooter) {
            return (null)
      }

      else {

            return (
                  <div id="footer">

                        <Row justify="center">

                              <Col xs={22} sm={22} md={22} lg={20} xxl={18}>

                                    <Row align="top">
                                          <Col xs={0} lg={12}>
                                                <img className="logo-footer" src={Logo} alt={"M J Church Skip Hire Logo"} />
                                          </Col>



                                          <Col xs={0} lg={12}>
                                                <a href="/"><img style={{ display: "block", marginLeft: "auto" }} src={OrderSkip} alt={"Order Skip Online"} /></a>
                                          </Col>
                                    </Row>

                                    <Row style={{ marginTop: "50px" }}>

                                          <Col flex="auto">
                                                <div className="footer-link" onClick={() => { navigate("/privacy") }}>Privacy & Data</div>
                                                <div className="footer-link" onClick={() => { navigate("/terms") }}>Terms & Conditions</div>
                                                <div className="footer-link" onClick={() => { navigate("/contact") }}>Contact Us</div>
                                          </Col>


                                    </Row>

                                    <Row>
                                          <Col flex="auto">
                                                <hr style={{ border: 0, marginTop: "50px", marginBottom: "25px", borderTop: "1px solid rgba(255,255,255,0.2)" }} />
                                                <p>© M J Church (Plant) Limited {moment().format("YYYY")}. All Rights Reserved</p>
                                                <p>Allington Transfer Station, Bristol Road, Allington, Chippenham, SN14 6LZ</p>
                                                <p>Registered Number: 1856955 | VAT Number: 399 0582 05</p>
                                                <p>Waste Carriers: CDBU91509</p>
                                          </Col>

                                    </Row>

                              </Col>

                        </Row>
                  </div>
            )
      }
}
