import { Row, Col } from "antd"

import BathRugby from '../images/client-logos/bath-rugby.png'
import BristolRugby from '../images/client-logos/bristol-bears.png'
import Badminton from '../images/client-logos/badminton.png'
import AirTattoo from '../images/client-logos/riat.png'


import { MyContext } from "../App"
import { useContext } from "react"

export function Clients() {


      let { AppData } = useContext(MyContext)

      if (AppData?.hideFooter) {
            return (null)
      }

      return (
            <>

                  <div id="work-with">

                        <Row justify="center" align="middle">

                              <Col xs={12} md={6}><img src={BristolRugby} alt="Bristol Bears Logo" /></Col>
                              <Col xs={12} md={6}><img src={Badminton} alt="Badminton Horse Trials Logo" /></Col>
                              <Col xs={12} md={6}><img src={BathRugby} alt="Bath Rugby Logo" /></Col>
                              <Col xs={12} md={6}><img src={AirTattoo} alt="Royal International Air Tattoo Logo" /></Col>

                        </Row>

                  </div>

            </>
      )
}