import { Row, Col, message, Divider } from 'antd'
import { useNavigate } from "react-router-dom"
import { useEffect, useState, useContext } from 'react'
import { SiContactlesspayment } from "react-icons/si"
import { SlCheck } from "react-icons/sl"
import { MyContext } from "../../App"

export function Complete() {

      const navigate = useNavigate()
      let { AppData } = useContext(MyContext)
      let [ id, setID ] = useState(null)

      useEffect(() => {

            (async () => {

                  if (sessionStorage.getItem("_MJC_SESSION") === null) {
                        // No sessionStorage exists
                        console.log('no sessionStorage()')
                        message.error("Your order session has expired. Please re-enter your postcode for the latest prices.")
                        navigate('/', { replace: true })
                  }

                  else {
                        setID(sessionStorage.getItem("_MJC_SESSION"))
                        sessionStorage.removeItem("_MJC_SESSION")
                  }

            })()

            // eslint-disable-next-line
      }, [])

      return (
            <>
                  <Row justify="center" style={{ margin: '100px 0' }}>

                        <Col xs={22} sm={22} md={22} lg={20} xxl={18}>

                              <h1><SiContactlesspayment style={{ fontSize: "120%", verticalAlign: "-12px", marginRight: "10px" }} />Payment complete</h1>
                              <p style={{ marginTop: "-15px", marginLeft: "25px", color: "green" }}><SlCheck style={{ color: "green", verticalAlign: "-3px", marginRight: "5px" }} /> {id}</p>
                              <Divider style={{ margin: "50px 0" }} />
                              <p style={{ fontSize: "150%" }}>Thank you for your order.</p>
                              <p>We're really pleased you've chosen us to supply you with a skip and appreciate your business. An email confirmation should arrive in your inbox within a few minutes. Should nothing arrive within 10 minutes, check your spam/junk folder. Our customer care team will be in touch shortly to discuss your delivery.</p>
                              <p>If you experience any issues or just want to check in with us about something, please call the office.</p>
                              <p>Thanks again</p>
                              <p style={{ fontWeight: "500", marginBottom: "0" }}>Skip Hire Customer Services</p>
                              <p>t: {AppData.telephone}</p>

                        </Col>

                  </Row>

            </>
      )
}